/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ListField, TextField } from 'shared/uibuilder/field';
import { EmployeeLinkFieldWithNameLoading } from 'erp/employee';

const EmployeePeopleManagersField = (props: any) => {
  const { data: showData } = useShowContext();
  const hasSpecificManagers = showData?.getValueBySource('hasSpecificManagers');

  return !hasSpecificManagers ? (
    <TextField value="All People Managers" label="People Managers" />
  ) : (
    <ListField source="assignedPeopleManagerAliases" label="People Managers">
      <EmployeeLinkFieldWithNameLoading />
    </ListField>
  );
};

export default EmployeePeopleManagersField;
