import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getProbationReduction } from 'erp/employee/finance/shared/financeService';
import { NumberInput } from 'shared/uibuilder/form/input';

type OfferProbationPeriodReductionInputProps = {
  source: string;
  probationAmountSource: string;
  baseAmountSource: string;
  saveToContext?: boolean;
  [key: string]: any;
};

const OfferProbationPeriodReductionInput = ({
  probationAmountSource,
  baseAmountSource,
  source,
  saveToContext = false,
  ...props
}: OfferProbationPeriodReductionInputProps) => {
  const { getValue: getProbationAmountValue } = useInputHelper({ source: probationAmountSource });
  const { getValue: getBaseAmountValue } = useInputHelper({ source: baseAmountSource });
  const { getRawOnChangeCallback } = useInputHelper({ source });

  const changeCallback = getRawOnChangeCallback()!;
  const probationAmountValue = getProbationAmountValue();
  const baseAmountValue = getBaseAmountValue();

  const getFieldValue = () => {
    if (!baseAmountValue || !probationAmountValue) {
      return null;
    }
    const reduction = getProbationReduction(probationAmountValue, baseAmountValue);
    return reduction === '' ? null : reduction;
  };

  useEffect(() => {
    if (saveToContext) {
      changeCallback({
        [source]: getFieldValue(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveToContext, probationAmountValue, baseAmountValue]);

  return <NumberInput value={getFieldValue()} source={source} disabled {...props} />;
};

export default OfferProbationPeriodReductionInput;
