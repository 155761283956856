/* istanbul ignore file */
// libs
import React, { CSSProperties } from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { CurrencyInput, DateInput, SelectInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import OpportunityTypeDropdown from 'crm/opportunity/shared/input/OpportunityTypeDropdown';
import OpportunityStageInput from 'crm/opportunity/shared/input/OpportunityStageInput';
import OpportunityOwnerInput from 'crm/opportunity/shared/input/OpportunityOwnerInput';
import { AccountInput } from 'crm/account';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import FormInputGroup from 'shared/layout/form/FormInputGroup';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
// constants
import { CLOSED_LOST, CLOSED_WON, POSTPONED } from 'crm/opportunity/shared/opportunityService';
import ClosedReasonDropdown from 'crm/opportunity/shared/input/ClosedReasonDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';
import BooleanDropdownOptionInput, { BOOLEAN_VALUE } from 'shared/uibuilder/form/input/BooleanDropdownOptionInput';
import RelatedDiscoveryOpportunityInputDropdown from 'crm/opportunity/shared/input/RelatedDiscoveryOpportunityInputDropdown';
import OpportunityChecklist from 'crm/shared/checklist/OpportunityChecklist';

interface OpportunityFormProps {
  isCreateForm?: boolean;
}

const ratesOptions: OptionType[] = [
  { value: 'by', label: 'BY' },
  { value: 'eu', label: 'EU' },
];

/**
 * Transform form data before submission
 * - For isContractRenewal=NO, combine checklist attachments with main attachments
 */
export const transformFormData = (data: any): any => {
  if (!data) return data;

  const transformedData = { ...data };

  // For isContractRenewal=NO, combine checklist attachments with main attachments
  if (transformedData.isContractRenewal === BOOLEAN_VALUE.NO) {
    const inputMaterials = transformedData.opportunityChecklist?.inputMaterials || [];
    const firstCallRecording = transformedData.opportunityChecklist?.firstCallRecording || [];
    const currentAttachments = transformedData.attachmentsIds || [];

    transformedData.attachmentsIds = [...new Set([...currentAttachments, ...inputMaterials, ...firstCallRecording])];
  }

  return transformedData;
};

const customSxChild = {
  2: {
    flex: {
      xs: `0 0 calc(100% - 16px)`,
      sm: `0 0 calc(100% - 16px)`,
      md: `0 0 calc(100% - 16px)`,
      xl: `0 0 calc(50% - 16px)`,
    },
    maxWidth: {
      xs: `calc(100% - 16px)`,
      sm: `calc(100% - 16px)`,
      md: `calc(100% - 16px)`,
      xl: `calc(50% - 16px)`,
    },
  },
};

const formSectionStyle: CSSProperties = {
  marginBottom: '15px',
};

const OpportunityForm: React.FC<OpportunityFormProps> = ({ isCreateForm }) => {
  const { isFeatureEnabled } = useFeatureToggle();

  const isClosedReasonInputsVisible = (opportunity: any) =>
    isFeatureEnabled(Features.OPPORTUNITY_CLOSED_REASON) &&
    opportunity?.stateStep &&
    (opportunity?.stateStep?.toString() === CLOSED_WON.toString() ||
      opportunity?.stateStep?.toString() === CLOSED_LOST.toString());

  return (
    <>
      <FormSection formStyle={formSectionStyle}>
        <FormRow customSxChild={customSxChild}>
          <TextInput source="name" label="Title" isRequired />
          <OpportunityTypeDropdown source="type" isRequired />
          <OpportunityStageInput source="stateStep" disabled={isCreateForm} />
        </FormRow>

        <FormRow>
          <DateInput
            source="closeDate"
            label="Closing date"
            tooltip="The final date by when the customer will make a decision"
          />
          <ClearAfterUnmountWrapper isVisible={opportunity => opportunity.stateStep === POSTPONED}>
            <DateInput source="postponedUntil" />
          </ClearAfterUnmountWrapper>
          <BooleanDropdownOptionInput source="isContractRenewal" label="Is this a renewal of an existing contract?" />
          <OpportunityOwnerInput source="ownerId" />
        </FormRow>

        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <ClosedReasonDropdown
              type="PRIMARY"
              source="closedReason.primaryReason"
              stageSource="stateStep"
              label="Primary Reason for Closed Status"
              placeholder="Choose Primary Closed Reason"
              tooltip="Select the primary reason of why the Opportunity has been Won (Lost)."
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <ClosedReasonDropdown
              type="SECONDARY"
              source="closedReason.secondaryReason"
              stageSource="stateStep"
              label="Secondary Reason for Closed Status"
              placeholder="Choose Secondary Closed Reason"
              tooltip="Select the secondary reason of why the Opportunity has been Won (Lost)."
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <TextArea
              label="Closed Summary"
              source="closedReason.summary"
              tooltip="Provide a summary of why the Opportunity was Won (Lost). Quote prospect's feedback, when available."
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
      </FormSection>

      <FormSection formStyle={formSectionStyle}>
        <AccountInput source="" idSource="accountId" nameSource="accountName" contactSource="primaryContactId" />
      </FormSection>
      <FormSection
        formStyle={formSectionStyle}
        isVisible={(data: any) =>
          Boolean(isCreateForm) &&
          data.isContractRenewal === 'NO' &&
          data.accountType === 'EXISTING' &&
          !!data.accountId
        }
      >
        <FormRow>
          <ClearAfterUnmountWrapper
            isVisible={data => data.isContractRenewal === 'NO' && data.accountType === 'EXISTING' && !!data.accountId}
          >
            <BooleanDropdownOptionInput
              source="isAfterDiscovery"
              label="Is Development After Discovery"
              placeholder="Select..."
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={data => data.isAfterDiscovery === 'YES'}>
            <RelatedDiscoveryOpportunityInputDropdown
              accountAliasSource="accountId"
              source="discoveryOpportunityId"
              label="Discovery Opportunity"
              errorSource="discoveryOpportunity"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection formStyle={formSectionStyle}>
        <FormRow>
          <SelectInput
            label="Rates"
            tooltip="What rates should be used for pricing (BY or EU)"
            source="rates"
            options={ratesOptions}
            isRequired
          />
        </FormRow>
        <FormRow>
          <CurrencyInput
            label="Maximum total cost"
            tooltip="The upper limit of the expected budget, i.e. we will definitely lose the opportunity if we go over this budget"
            source="upperLimit"
            isRequired
            suffix="$"
          />
          <CurrencyInput
            label="Most expected total cost"
            tooltip="Most likely available budget based on information provided by the customer or our best guess"
            source="mostExpected"
            isRequired
            suffix="$"
          />
          <CurrencyInput
            label="Minimum recommended cost"
            tooltip="The lower boundary of the suggested budget"
            source="lowerLimit"
            isRequired
            suffix="$"
          />
        </FormRow>
      </FormSection>

      <FormSection formStyle={formSectionStyle}>
        <MediumFormRow>
          <TextArea label="Description" source="description" rows={1} />
          <MediumFormRow>
            <TextInput source="nextStep" />
            <DateInput source="nextStepDeadline" />
          </MediumFormRow>
        </MediumFormRow>
      </FormSection>

      <FormSection
        formStyle={formSectionStyle}
        isVisible={data => !isCreateForm || data?.isContractRenewal === BOOLEAN_VALUE.YES}
      >
        <BigFormRow>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>

      <FormInputGroup isVisible={data => data?.isContractRenewal === BOOLEAN_VALUE.NO && Boolean(isCreateForm)}>
        <OpportunityChecklist source="opportunityChecklist" hideAttachmentSections={false} />
      </FormInputGroup>
    </>
  );
};

export default OpportunityForm;
