import React, { useEffect } from 'react';
import { SelectInput, DateInput, TextArea, Checkbox } from 'shared/uibuilder/form/input';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import FormSection from 'shared/layout/form/FormSection';
import FormInputGroup from 'shared/layout/form/FormInputGroup';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import FormRow from 'shared/layout/form/FormRow';

export interface OpportunityChecklistData {
  expectedBusinessOutcomes?: string;
  perceivedPriority?: string;
  rates?: string;
  expectedStartDate?: string;
  expectedDeliveryDate?: string;
  timelinesBasis?: string;
  hasWorkedWithTeamBefore?: string;
  hasOwnEngineering?: string;
  techStackConstraints?: string;
  inputMaterials?: string[];
  firstCallRecording?: string[];
  noCallRecordingPossible?: boolean;
}

const getFieldLabel = (field: string): string => {
  const labels: Record<string, string> = {
    expectedBusinessOutcomes: 'Expected business outcomes',
    perceivedPriority: 'Key project priority (scope or timeline)',
    rates: 'Rates',
    expectedStartDate: 'Expected start date',
    expectedDeliveryDate: 'Expected delivery date',
    timelinesBasis: 'Rationale behind the start and the delivery dates',
    hasWorkedWithTeamBefore: 'Custom software engineering experience',
    hasOwnEngineering: 'In-house engineering or IT',
    techStackConstraints: 'Technology constraints',
    noCallRecordingPossible: 'Recording of the first call was not possible',
    inputMaterials: 'Additional materials',
  };

  return labels[field] || field;
};

export const transformChecklistData = (data: OpportunityChecklistData) => {
  if (!data) return { content: '', attachments: [] };

  const attachments = [...(data.inputMaterials || []), ...(data.firstCallRecording || [])];

  const contentParts: string[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'inputMaterials' || key === 'firstCallRecording') return;

    if (!value) return;

    const label = getFieldLabel(key);
    contentParts.push(`<p><strong>${label}</strong>: ${value}</p>`);
  });

  return {
    content: contentParts.join('\n'),
    attachments,
  };
};

const priorityOptions: OptionType[] = [
  { value: 'timeline', label: 'Timeline' },
  { value: 'scope', label: 'Scope' },
];

interface OpportunityChecklistProps {
  source: string;
  hideAttachmentSections?: boolean;
}

const OpportunityChecklist: React.FC<OpportunityChecklistProps> = ({ source, hideAttachmentSections = false }) => {
  const { data, setFormData } = useFormContext();
  const getFieldSource = (field: string) => `${source}.${field}`;
  const getFieldValue = (field: string) => data?.[source]?.[field];

  useEffect(() => {
    return () => {
      if (setFormData) {
        setFormData((prevData: Record<string, any>) => {
          const newData = { ...prevData };
          delete newData[source];
          return newData;
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return (
    <>
      <FormSection>
        <FormRow colMd={6} colXl={3}>
          <DateInput label="Expected start date" source={getFieldSource('expectedStartDate')} isRequired />
          <DateInput label="Expected delivery date" source={getFieldSource('expectedDeliveryDate')} isRequired />
          <SelectInput
            label="Key project priority"
            tooltip="What do you think would be more important from the customer perspective in a situation when we can choose only one (scope or timeline)"
            source={getFieldSource('perceivedPriority')}
            options={priorityOptions}
            isRequired
          />
        </FormRow>
        <MediumFormRow>
          <TextArea
            label="Rationale behind the start and the delivery dates"
            tooltip="What is the reason for setting these specific dates"
            source={getFieldSource('timelinesBasis')}
            isRequired
          />
          <TextArea
            label="Expected business outcomes"
            tooltip="What business outcomes the customer expects to get as the result of investing into the project"
            source={getFieldSource('expectedBusinessOutcomes')}
            isRequired
          />
        </MediumFormRow>
        <MediumFormRow>
          <FormInputGroup>
            <TextArea
              label="Custom software engineering experience"
              tooltip="Describe what experience the customer had with custom software engineering previously. Describe what did the do and what was. their role in the process"
              source={getFieldSource('hasWorkedWithTeamBefore')}
              isRequired
            />
          </FormInputGroup>
          <FormInputGroup>
            <TextArea
              label="In-house engineering or IT"
              tooltip="Describe what capabilities the customer has in-house, what do these people do and what is their expected involvement in the potential project"
              source={getFieldSource('hasOwnEngineering')}
              isRequired
            />
          </FormInputGroup>
        </MediumFormRow>

        <MediumFormRow>
          <TextArea
            label="Technology constraints"
            tooltip="Outline constraints (if any) for the technology, for example programming language, or cloud hosting provider"
            source={getFieldSource('techStackConstraints')}
            isRequired
          />
        </MediumFormRow>
      </FormSection>

      {!hideAttachmentSections && (
        <FormSection>
          <MediumFormRow>
            <>
              <CrmAttachmentUploaderInput
                label="Recording of the 1st call with the customer"
                source={getFieldSource('firstCallRecording')}
                isRequired={!getFieldValue('noCallRecordingPossible')}
              />
              <Checkbox
                label="Recording of the first call was not possible"
                source={getFieldSource('noCallRecordingPossible')}
              />
            </>
            <CrmAttachmentUploaderInput
              label="Additional materials"
              tooltip="Attach any additional materials, for example requirements, UI mockups, source code, or others"
              source={getFieldSource('inputMaterials')}
            />
          </MediumFormRow>
        </FormSection>
      )}
    </>
  );
};

export default OpportunityChecklist;
