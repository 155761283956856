/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox } from 'shared/uibuilder/form/input';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import PeopleManagerDropdown from 'erp/employee/shared/input/PeopleManagerDropdown';

const PeopleManagementSpecifySection = () => {
  const { data: formData, onChangeCallback, isDirty } = useFormContext();
  const assignedPeopleManagerAliases = formData?.assignedPeopleManagerAliases || [];
  const hasSpecificManagers = formData?.hasSpecificManagers;
  return (
    <FormRow>
      <Checkbox
        source="isAccessForAllPeopleManagers"
        label="All people managers will work with the employee"
        onChangeCallback={(formFields: FormFieldsData) => {
          const { isAccessForAllPeopleManagers } = formFields;
          const specificFlag =
            !isDirty && hasSpecificManagers != null ? hasSpecificManagers : !isAccessForAllPeopleManagers;
          const isAccessForAll = !specificFlag;
          const pplManagers = isAccessForAll ? [] : assignedPeopleManagerAliases;
          if (onChangeCallback) {
            onChangeCallback({
              isAccessForAllPeopleManagers: isAccessForAll,
              hasSpecificManagers: specificFlag,
              assignedPeopleManagerAliases: pplManagers,
            });
          }
        }}
      />
      <PeopleManagerDropdown
        source="assignedPeopleManagerAliases"
        label="People Managers"
        multiple
        disabled={(data: any) => data?.isAccessForAllPeopleManagers}
        helpText="If no managers are selected, all People Managers will be assigned by default."
      />
    </FormRow>
  );
};

export default PeopleManagementSpecifySection;
