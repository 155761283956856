/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import BaseAmountTypeDropdown from 'erp/employee/finance/shared/inputs/BaseAmountTypeDropdown';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import EmployeeContractTypeDropdown from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import Box from 'uibuilder/Box';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';
import OfferProbationPeriodReductionInput from '../shared/field/OfferProbationPeriodReductionInput';

interface OfferCompensationDetailsInputProps {
  source: string;
}

const CandidateOfferCompensationDetailsInput = ({ source, ...props }: OfferCompensationDetailsInputProps) => {
  return (
    <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', xl: '100%' } }}>
      <FormRow colXl={4}>
        <BaseAmountTypeDropdown source={`${source}.baseAmountType`} placeholder=" " label="Base Amount Type" />
        <CurrencyDropdown source={`${source}.currency`} label="Currency" placeholder=" " />
        <TextInput source={`${source}.finalAmountGross`} label="Final Amount Gross" />

        <TextInput source={`${source}.probationAmountGross`} label="Probation Amount Gross" />
        <TextInput
          source={`${source}.calculatedAmountGross`}
          label="Calculated Amount Gross"
          tooltip="Amount calculated by qualification model"
        />
        <OfferProbationPeriodReductionInput
          saveToContext
          source={`${source}.probationPeriodReduction`}
          probationAmountSource={`${source}.probationAmountGross`}
          baseAmountSource={`${source}.finalAmountGross`}
          label="Probation Period Reduction, %"
        />

        <OfficeDropdown
          mapResults={(office: any) => ({
            value: OFFICE_NAMES[office.name].toUpperCase(),
            label: OFFICE_NAMES[office.name],
          })}
          source={`${source}.office`}
          label="Office"
          placeholder=" "
        />
        <EmployeeContractTypeDropdown source={`${source}.contractType`} label="Contract Type" placeholder=" " />
      </FormRow>
    </Box>
  );
};

export default CandidateOfferCompensationDetailsInput;
