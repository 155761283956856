import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SalaryForkLevelsForm from './SalaryForkLevelsForm';
import useSalaryForkService, { SALARY_FORKS_CREATE, SALARY_FORKS_UPDATE } from '../useSalaryForkService';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { CreateForm } from 'shared/uibuilder/form';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import { SalaryFork } from '../types';
import SalaryForkMenu from './SalaryForkMenu';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import Loading from 'shared/uibuilder/Loading';
import SalaryForkButtonsLayout from './SalaryForkButtons';
import useAuthorization from 'shared/authorization/authorizationService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { RECRUITING_CONTEXT } from '../../RecruitingContext';

// Component to handle permission-based form submission
const FormPermissionCheck = ({ permission }: { permission: boolean }) => {
  const formContext = useFormContext();

  useEffect(() => {
    if (formContext?.setSubmitEnabled) {
      formContext.setSubmitEnabled(permission);
    }
  }, [permission, formContext]);

  return null;
};

interface SalaryForkListLayoutProps {
  onSpecializationStatusChange?: (specializationId: string | null) => void;
}

const SalaryForkListLayout: React.FC<SalaryForkListLayoutProps> = ({ onSpecializationStatusChange }) => {
  const [selectedSpecialization, setSelectedSpecialization] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState<Nullable<SalaryFork>>(null);
  const [initialData, setInitialData] = useState<Nullable<SalaryFork>>(null);
  const { addMessage, cleanMessage } = useMessageService();
  const {
    updateSalaryFork,
    createSalaryFork,
    getCreateValidationSchema,
    getUpdateValidationSchema,
    createInitialData,
    searchSalaryForksForSpec,
  } = useSalaryForkService();
  const { isGranted } = useAuthorization();
  const canCreate = isGranted(SALARY_FORKS_CREATE, RECRUITING_CONTEXT);
  const canUpdate = isGranted(SALARY_FORKS_UPDATE, RECRUITING_CONTEXT);

  const fetchSalaryForkData = async (specializationAlias: string) => {
    try {
      const salaryForks = await searchSalaryForksForSpec(specializationAlias);
      setCurrentData(salaryForks);
      setInitialData(salaryForks);

      onSpecializationStatusChange?.(salaryForks.id || null);
    } catch (error) {
      addMessage(new ErrorMessage('Error fetching salary fork data'));
      setCurrentData(null);
      setInitialData(null);
      onSpecializationStatusChange?.(null);
    }
  };

  const handleSpecializationChange = async (specializationAlias: string) => {
    cleanMessage();
    setSelectedSpecialization(specializationAlias);
    setLoading(true);
    setCurrentData(null);
    setInitialData(null);
    onSpecializationStatusChange?.(null);

    try {
      await fetchSalaryForkData(specializationAlias);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      onSpecializationStatusChange?.(null);
    };
  }, [onSpecializationStatusChange]);

  const handleUpdate = async (id: StringOrNumber, values: any) => {
    if (!canUpdate) {
      addMessage(new ErrorMessage('You do not have permission to update salary forks'));
      return null;
    }

    setLoading(true);
    try {
      const result = await updateSalaryFork(values, initialData);
      if (selectedSpecialization) {
        await fetchSalaryForkData(selectedSpecialization);
      }
      return result;
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async (values: any) => {
    if (!canCreate) {
      addMessage(new ErrorMessage('You do not have permission to create salary forks'));
      return null;
    }

    setLoading(true);
    try {
      const result = await createSalaryFork(values);
      await fetchSalaryForkData(result.specialization);
      return result;
    } finally {
      setLoading(false);
    }
  };

  const handleCleanClick = () => {
    setCurrentData(initialData);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <SalaryForkMenu
        onSpecializationChange={handleSpecializationChange}
        selectedSpecialization={selectedSpecialization}
      />
      {loading && <Loading />}
      {selectedSpecialization && (
        <Box className={loading ? 'visually-hidden' : ''} sx={{ width: '100%' }}>
          {currentData?.id ? (
            <UpdateForm
              getDataFunc={() => Promise.resolve(currentData)}
              submitFormFunc={handleUpdate}
              getValidationSchemaFunc={getUpdateValidationSchema}
              afterSubmit={{
                message: 'Salary Ranges successfully updated',
              }}
            >
              <UpdatePageLayout
                buttons={<SalaryForkButtonsLayout handleCleanClick={handleCleanClick} initialData={initialData} />}
              >
                <SalaryForkLevelsForm />
                <FormPermissionCheck permission={canUpdate} />
              </UpdatePageLayout>
            </UpdateForm>
          ) : (
            <CreateForm
              submitFormFunc={handleCreate}
              getValidationSchemaFunc={getCreateValidationSchema}
              initialData={createInitialData(selectedSpecialization)}
              afterSubmit={{
                message: 'Salary Ranges successfully created',
              }}
            >
              <CreatePageLayout
                buttons={
                  <SalaryForkButtonsLayout
                    handleCleanClick={handleCleanClick}
                    initialData={createInitialData(selectedSpecialization)}
                  />
                }
              >
                <SalaryForkLevelsForm />
                <FormPermissionCheck permission={canCreate} />
              </CreatePageLayout>
            </CreateForm>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SalaryForkListLayout;
