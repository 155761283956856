/* istanbul ignore file */
import React from 'react';

import useOpportunityService, { DAYS_TO_CLOSE, NEW } from 'crm/opportunity/shared/opportunityService';
import OpportunityForm, { transformFormData } from 'crm/opportunity/createupdate/OpportunityForm';
import { transformChecklistData } from 'crm/shared/checklist/OpportunityChecklist';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateOpportunityBreadcrumbs from 'crm/opportunity/createupdate/CreateOpportunity/Breadcrumbs';
import useRelatedRedirect from 'shared/routing/redirect/relatedRedirect';
import { useAccountUrl } from 'crm/account/index';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useSearchParameter } from 'shared/routing/helper';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const CreateOpportunity = () => {
  const { create, getValidationSchema } = useOpportunityService();
  const accountId = useSearchParameter('accountId');
  const accountName = useSearchParameter('accountName');
  const { getCurrentDateTimeInUtc } = useDateService();
  const currentDate = getCurrentDateTimeInUtc();
  const initialCloseDate = currentDate.add(DAYS_TO_CLOSE, 'days').format(DATE_FORMAT.INPUT_VALUE);

  const { getRelatedOpportunitiesUrl } = useAccountUrl();
  const { getListUrl } = useOpportunityUrl();

  const { redirect } = useRelatedRedirect({
    message: 'You are awesome! The Opportunity has been successfully created.',
    changedUrl: getListUrl(),
    unchangedUrl: getRelatedOpportunitiesUrl(accountId!),
    fields: {
      accountId,
    },
  });

  return (
    <CreateForm
      submitFormFunc={data => {
        const transformedData = transformFormData(data);

        if (transformedData?.opportunityChecklist) {
          transformedData.opportunityChecklist = transformChecklistData(transformedData.opportunityChecklist);
        }
        return create(transformedData);
      }}
      initialData={{
        accountId,
        accountName,
        originalAccountName: '',
        stateStep: NEW,
        closeDate: initialCloseDate,
      }}
      afterSubmit={{
        execute: redirect,
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Opportunity" breadcrumbs={<CreateOpportunityBreadcrumbs />}>
        <OpportunityForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateOpportunity;
