/* istanbul ignore file */
export default {
  accountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'NEW',
        },
      },
      message: 'Please fill this field',
    },
  },
  originalAccountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'NEW',
        },
      },
      message: 'Please fill this field',
    },
  },
  existingAccountName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'EXISTING',
        },
      },
      message: 'Please choose an account from the list',
    },
  },
  firstName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      message: 'Please fill this field',
    },
  },
  title: {
    type: 'string',
    maxSize: 255,
    required: {
      value: false,
    },
  },
  middleName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: false,
    },
  },
  lastName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      message: 'Please fill this field',
    },
  },
  createOpportunity: {
    type: 'boolean',
    required: {
      value: false,
    },
  },
  opportunityName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please fill this field',
    },
  },
  closeDate: {
    type: 'date',
    minDate: {
      value: '$TODAY',
      message: 'Please choose date in future',
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
    },
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please select the date',
    },
  },
  rates: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please select rate type',
    },
  },
  upperLimit: {
    type: 'number',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please enter upper limit',
    },
  },
  mostExpected: {
    type: 'number',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please enter most expected amount',
    },
  },
  lowerLimit: {
    type: 'number',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please enter lower limit',
    },
  },
  opportunityChecklist: {
    type: 'object',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          eq: true,
        },
      },
    },
    properties: {
      expectedBusinessOutcomes: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please fill this field',
        },
      },
      perceivedPriority: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please select a priority',
        },
      },
      expectedStartDate: {
        type: 'date',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please select start date',
        },
      },
      expectedDeliveryDate: {
        type: 'date',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please select delivery date',
        },
      },
      timelinesBasis: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please fill this field',
        },
      },
      hasWorkedWithTeamBefore: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please select an option',
        },
      },
      whatDidTheyDo: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
            '$opportunityChecklist.hasWorkedWithTeamBefore': {
              eq: 'yes',
            },
          },
          message: 'Please fill this field',
        },
      },
      theirRoleInProcess: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
            '$opportunityChecklist.hasWorkedWithTeamBefore': {
              eq: 'yes',
            },
          },
          message: 'Please fill this field',
        },
      },
      hasOwnEngineering: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please select an option',
        },
      },
      whatDoTheyDo: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
            '$opportunityChecklist.hasOwnEngineering': {
              eq: 'yes',
            },
          },
          message: 'Please fill this field',
        },
      },
      howInvolved: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
            '$opportunityChecklist.hasOwnEngineering': {
              eq: 'yes',
            },
          },
          message: 'Please fill this field',
        },
      },
      techStackConstraints: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createOpportunity: {
              ne: true,
            },
          },
          message: 'Please fill this field',
        },
      },
      inputMaterials: {
        type: 'array',
        required: {
          value: false,
        },
      },
      firstCallRecording: {
        type: 'array',
        required: {
          value: true,
          when: {
            '$opportunityChecklist.noCallRecordingPossible': {
              eq: false,
            },
            $createOpportunity: {
              ne: true,
            },
          },
        },
      },
      noCallRecordingPossible: {
        type: 'boolean',
        required: {
          value: false,
        },
      },
    },
  },
};
