/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { TextInput, DateInput, CurrencyInput, SelectInput } from 'shared/uibuilder/form/input';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { AccountInput } from 'crm/account';
import OpportunityCheckbox from 'crm/lead/shared/field/OpportunityCheckbox';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import OpportunityChecklist from 'crm/shared/checklist/OpportunityChecklist';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';

const ratesOptions: OptionType[] = [
  { value: 'by', label: 'BY' },
  { value: 'eu', label: 'EU' },
];

const ConvertLeadForm = () => {
  const { data } = useFormContext();
  const createOpportunity = Boolean(data?.createOpportunity);

  return (
    <>
      <FormSection>
        <AccountInput idSource="existingAccountName" nameSource="accountName" source="" />
      </FormSection>

      <FormSection subtitle="Create new contact">
        <FormRow>
          <TextInput source="title" />
          <TextInput source="firstName" />
          <TextInput source="middleName" />
          <TextInput source="lastName" />
        </FormRow>
      </FormSection>

      <FormSection>
        <BigFormRow>
          <OpportunityCheckbox nameSource="createOpportunity" label="Do not create a new opportunity upon conversion" />
        </BigFormRow>
        <FormRow>
          <TextInput source="opportunityName" disabled={createOpportunity} />
          <DateInput source="closeDate" disabled={createOpportunity} />
        </FormRow>

        <FormRow>
          <SelectInput
            label="Rates"
            tooltip="What rates should be used for pricing (BY or EU)"
            source="rates"
            options={ratesOptions}
            isRequired
          />
          <CurrencyInput
            label="Max. total cost"
            tooltip="The upper limit of the expected budget, i.e. we will definitely lose the opportunity if we go over this budget"
            source="upperLimit"
            isRequired
            suffix="$"
          />
          <CurrencyInput
            label="Most expected total cost"
            tooltip="Most likely available budget based on information provided by the customer or our best guess"
            source="mostExpected"
            isRequired
            suffix="$"
          />
          <CurrencyInput
            label="Min. recommended cost"
            tooltip="The lower boundary of the suggested budget"
            source="lowerLimit"
            isRequired
            suffix="$"
          />
        </FormRow>
      </FormSection>

      {!createOpportunity && <OpportunityChecklist source="opportunityChecklist" />}
    </>
  );
};

export default ConvertLeadForm;
