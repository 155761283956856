import React, { useState, useCallback } from 'react';
import { Box, Button, Stack } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import SalaryForkListLayout from './SalaryForkListLayout';
import { Link } from 'react-router-dom';
import { useSalaryForksUrl } from '../routing';
import useAuthorization from 'shared/authorization/authorizationService';
import { SALARY_FORKS_READ_HISTORY } from '../useSalaryForkService';

const SalaryForkPage = () => {
  const [selectedSpecialization, setSelectedSpecialization] = useState<string | null>(null);
  const { getHistoryUrl } = useSalaryForksUrl();
  const { isGranted } = useAuthorization();
  const canViewHistory = isGranted(SALARY_FORKS_READ_HISTORY, 'Recruiting');

  const handleSpecializationChange = useCallback((specializationId: string | null) => {
    setSelectedSpecialization(specializationId);
  }, []);

  const historyUrl = getHistoryUrl(selectedSpecialization || '');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }} data-hj-suppress>
      <ListHeader label="Salary Ranges" />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Stack direction="row" spacing={2}>
          {canViewHistory && (
            <Button
              variant="outlined"
              startIcon={<HistoryIcon />}
              component={Link}
              to={historyUrl}
              disabled={!selectedSpecialization}
            >
              View History
            </Button>
          )}
        </Stack>
      </Box>

      <SalaryForkListLayout onSpecializationStatusChange={handleSpecializationChange} />
    </Box>
  );
};

export default SalaryForkPage;
