import { useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import usePositionService from '../../position/positionService';
import createSalaryForkSchema from './createupdate/create/createSalaryForkSchema';
import updateSalaryForkSchema from './createupdate/update/updateSalaryForkSchema';
import {
  SensitiveRecruitingInfo,
  SalaryFork,
  CreateSalaryForkCommand,
  UpdateSalaryForkCommand,
  SearchRequest,
  SalaryForkValue,
} from './types';
import { v4 as uuidv4 } from 'uuid';
import { COMPETENCY_LEVEL_WITHOUT_NA } from '../vacancy/shared/vacancyService';
import useRecruitingFinanceService from '../useRecruitingFinanceService';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import { COMPENSATION_CURRENCY_TYPES } from '../newVacancy/constants';

export const SALARY_FORKS_VIEW_READ = 'RECRUITING_SALARY_FORKS_LIST_GET_ALL_SALARY_FORKS';

export const SALARY_FORKS_CREATE = 'RECRUITING_SALARY_FORK_CREATE_SALARY_FORK';
export const SALARY_FORKS_UPDATE = 'RECRUITING_SALARY_FORK_UPDATE_SALARY_FORK';
export const SALARY_FORKS_READ_HISTORY = 'RECRUITING_SALARY_FORK_READ_HISTORY';

export const KERNEL_SALARY_FORKS_VIEW_READ = 'COMPANY_READ_SALARY_FORKS';

const SALARY_FORKS = '/salary-forks';
export interface SalaryForkChangeItem {
  property: string;
  oldValue: string | null;
  newValue: string | null;
}

export interface SalaryForkHistoryItem {
  id: string;
  updatedBy: string;
  updatedAt: string;
  changes: {
    l1?: SalaryForkChangeItem[];
    l2?: SalaryForkChangeItem[];
    l3?: SalaryForkChangeItem[];
    l4?: SalaryForkChangeItem[];
    l5?: SalaryForkChangeItem[];
    l6?: SalaryForkChangeItem[];
    root?: SalaryForkChangeItem[];
    [key: string]: any;
  };
}

const createInitialLevelData = () => ({
  bySalaryFromId: null,
  bySalaryToId: null,
  byCurrency: COMPENSATION_CURRENCY_TYPES.USD as 'USD',
  plSalaryFromId: null,
  plSalaryToId: null,
  plCurrency: COMPENSATION_CURRENCY_TYPES.PLN as 'PLN',
});

export const createInitialData = (specialization: string): SalaryFork => {
  return {
    specialization,
    l1: createInitialLevelData(),
    l2: createInitialLevelData(),
    l3: createInitialLevelData(),
    l4: createInitialLevelData(),
    l5: createInitialLevelData(),
    l6: createInitialLevelData(),
  };
};

export interface EmployeeNamesMap {
  [key: string]: string;
}

export interface SalaryValuesMap {
  [key: string]: number;
}

const salaryValueFields = [
  { value: 'bySalaryFromValue', static: 'staticBySalaryFromValue', id: 'bySalaryFrom', currency: 'byCurrency' },
  { value: 'bySalaryToValue', static: 'staticBySalaryToValue', id: 'bySalaryTo', currency: 'byCurrency' },
  { value: 'plSalaryFromValue', static: 'staticPlSalaryFromValue', id: 'plSalaryFrom', currency: 'plCurrency' },
  { value: 'plSalaryToValue', static: 'staticPlSalaryToValue', id: 'plSalaryTo', currency: 'plCurrency' },
];

// Check if a level has any salary values
const levelHasSalaries = (level: any): boolean => {
  if (!level) return false;
  return Boolean(
    level.bySalaryFromValue ||
      level.bySalaryToValue ||
      level.plSalaryFromValue ||
      level.plSalaryToValue ||
      level.bySalaryFromId ||
      level.bySalaryToId ||
      level.plSalaryFromId ||
      level.plSalaryToId,
  );
};

const collectSalaryValues = (
  values: Record<string, any>,
  initialData: Nullable<Record<string, any>> = null,
): Array<{ value: number; externalId: string }> => {
  const salaryValues: Array<{ value: number; externalId: string }> = [];

  Object.entries(values).forEach(([key, level]: [string, any]) => {
    if (key === 'specialization' || key === 'id' || !level) {
      return;
    }

    const initialLevel = initialData?.[key] || null;

    salaryValueFields.forEach(field => {
      const value = parseFloat(level[field.value]);

      if (Number.isNaN(value)) {
        return;
      }

      const hasChanged = initialLevel ? value !== initialLevel[field.value] : true;

      if (hasChanged) {
        salaryValues.push({
          value,
          externalId: uuidv4(),
        });
      }
    });
  });

  return salaryValues;
};

const createSalaryForkValue = (
  values: Record<string, any>,
  savedSalaryMap: Record<string, string>,
  initialValues: Nullable<Record<string, any>> = null,
): SalaryForkValue => {
  const result: SalaryForkValue = {
    bySalaryFromId: null,
    bySalaryToId: null,
    byCurrency: null,
    plSalaryFromId: null,
    plSalaryToId: null,
    plCurrency: null,
  };

  // Check if there are BY salary values or IDs
  const hasBySalary =
    (values.bySalaryFromValue !== null && values.bySalaryFromValue !== undefined) ||
    (values.bySalaryToValue !== null && values.bySalaryToValue !== undefined) ||
    (values.bySalaryFromId !== null && values.bySalaryFromId !== undefined) ||
    (values.bySalaryToId !== null && values.bySalaryToId !== undefined);

  // Check if there are PL salary values or IDs
  const hasPlSalary =
    (values.plSalaryFromValue !== null && values.plSalaryFromValue !== undefined) ||
    (values.plSalaryToValue !== null && values.plSalaryToValue !== undefined) ||
    (values.plSalaryFromId !== null && values.plSalaryFromId !== undefined) ||
    (values.plSalaryToId !== null && values.plSalaryToId !== undefined);

  salaryValueFields.forEach(field => {
    const value = values[field.value];
    const hasChanged = initialValues ? value !== initialValues[field.value] : value !== null;

    const idField = `${field.id}Id` as keyof SalaryForkValue;
    const currencyField = field.currency as keyof SalaryForkValue;

    result[idField] = hasChanged && value !== null ? savedSalaryMap[value] : values[idField] || null;

    // Only set currency if there are corresponding salary values
    if (currencyField === 'byCurrency' && hasBySalary && values[field.currency]) {
      result[currencyField] = values[field.currency];
    } else if (currencyField === 'plCurrency' && hasPlSalary && values[field.currency]) {
      result[currencyField] = values[field.currency];
    }
  });

  return result;
};

const useSalaryForkService = () => {
  const { sendPostRequest, sendPutRequest } = useRecruitmentApi();
  const { saveBatchSensitiveRecruitingInfo, searchSensitiveRecruitingInfo } = useRecruitingFinanceService();
  const { findAll: findAllPositions } = usePositionService();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();

  const { search: baseSearch, ...baseCrud } = useCrudService({
    singleResourceUrl: `${SALARY_FORKS}/:id`,
    listResourceUrl: SALARY_FORKS,
    apiService: useRecruitmentApi,
  });

  const getSpecializationOptions = async () => {
    const positions = await findAllPositions();
    return positions.map((position: Dictionary<string>) => ({
      alias: position.vacancyAlias,
      name: position.name,
    }));
  };

  // Reusable function to process salary data
  const processSalaryData = async (values: FormFieldsData, initialData: Nullable<SalaryFork> = null) => {
    const salaryValues = collectSalaryValues(values, initialData);
    const savedSalaries = salaryValues.length > 0 ? await saveSensitiveRecruitingInfo(salaryValues) : [];

    const savedSalaryMap = savedSalaries.reduce(
      (acc: Record<string, string>, salary: any) => ({
        ...acc,
        [salary.value]: salary.id,
      }),
      {},
    );

    return savedSalaryMap;
  };

  // Process level data for both create and update operations
  const processLevels = (
    values: FormFieldsData,
    savedSalaryMap: Record<string, string>,
    initialData: Nullable<SalaryFork> = null,
    isUpdate = false,
  ): Record<string, any> => {
    const competencyLevels = Object.values(COMPETENCY_LEVEL_WITHOUT_NA) as string[];

    return competencyLevels.reduce((acc, level) => {
      const levelKey = level as keyof SalaryFork;
      const levelValue = values[levelKey];
      const initialLevelValue =
        initialData && initialData[levelKey] ? (initialData[levelKey] as Record<string, any>) : null;

      if (levelValue && levelHasSalaries(levelValue)) {
        const forkValue = createSalaryForkValue(levelValue, savedSalaryMap, initialLevelValue);

        if (isUpdate) {
          return {
            ...acc,
            [levelKey]: {
              ...forkValue,
              salaryForkValueId: levelValue.id,
            },
          };
        }

        return {
          ...acc,
          [levelKey]: forkValue,
        };
      }
      return {
        ...acc,
        [levelKey]: null,
      };
    }, {});
  };

  const createSalaryFork = async (values: FormFieldsData) => {
    const savedSalaryMap = await processSalaryData(values);

    const command: CreateSalaryForkCommand = {
      specialization: values.specialization as string,
      ...(processLevels(values, savedSalaryMap) as Pick<
        CreateSalaryForkCommand,
        'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6'
      >),
    };

    const res = await sendPostRequest(SALARY_FORKS, command);
    return res.json();
  };

  const updateSalaryFork = async (values: FormFieldsData, initialData: Nullable<SalaryFork>) => {
    const savedSalaryMap = await processSalaryData(values, initialData);

    const command: UpdateSalaryForkCommand = {
      salaryForkId: values.id as string,
      id: values.id as string,
      ...(processLevels(values, savedSalaryMap, initialData, true) as Pick<
        UpdateSalaryForkCommand,
        'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6'
      >),
    };

    const res = await sendPutRequest(`${SALARY_FORKS}/${values.id}`, command);
    return res.json();
  };

  const searchSalaryForksForSpec = async (specializationId: string) => {
    const searchRequest: SearchRequest = {
      pageNumber: 0,
      pageSize: 100,
      filter: {
        specialization: {
          eq: specializationId,
        },
      },
    };
    const salaryForks = await baseSearch(searchRequest);

    if (!salaryForks.result.length) {
      return createInitialData(specializationId);
    }

    const forks = salaryForks.result[0];
    const salaryIds = extractSalaryIds(forks);

    if (salaryIds.length === 0) {
      return createInitialData(forks.specialization);
    }

    const salaryValues = await searchSensitiveRecruitingInfo(salaryIds);
    const salaryMap = createSalaryMap(salaryValues);

    return {
      id: forks.id,
      specialization: forks.specialization,
      ...Object.values(COMPETENCY_LEVEL_WITHOUT_NA).reduce((levelAcc: Record<string, any>, level: string) => {
        const levelData = forks[level];
        if (levelData) {
          return {
            ...levelAcc,
            [level]: {
              ...levelData,
              bySalaryFromValue: levelData.bySalaryFromId ? salaryMap[levelData.bySalaryFromId] : null,
              bySalaryToValue: levelData.bySalaryToId ? salaryMap[levelData.bySalaryToId] : null,
              byCurrency: levelData.byCurrency || COMPENSATION_CURRENCY_TYPES.USD,
              plSalaryFromValue: levelData.plSalaryFromId ? salaryMap[levelData.plSalaryFromId] : null,
              plSalaryToValue: levelData.plSalaryToId ? salaryMap[levelData.plSalaryToId] : null,
              plCurrency: levelData.plCurrency || COMPENSATION_CURRENCY_TYPES.PLN,
            },
          };
        }
        return {
          ...levelAcc,
          [level]: createInitialLevelData(),
        };
      }, {}),
    } as SalaryFork;
  };

  const extractSalaryIds = (forks: any) => {
    return Object.values(COMPETENCY_LEVEL_WITHOUT_NA).reduce((ids: string[], level: string) => {
      const levelData = forks[level];
      if (levelData) {
        ['bySalaryFromId', 'bySalaryToId', 'plSalaryFromId', 'plSalaryToId'].forEach(field => {
          if (levelData[field]) ids.push(levelData[field]);
        });
      }
      return ids;
    }, []);
  };

  const createSalaryMap = (salaryValues: any[]): SalaryValuesMap => {
    return salaryValues.reduce(
      (acc: Record<string, number>, item: any) => ({
        ...acc,
        [item.id]: item.value,
      }),
      {},
    );
  };

  const extractEmployeeAliasesFromHistory = (historyItems: SalaryForkHistoryItem[]): string[] => {
    const aliases = new Set<string>();

    historyItems.forEach(item => {
      if (item.updatedBy) {
        aliases.add(item.updatedBy);
      }
    });

    return Array.from(aliases);
  };

  const extractSalaryIdsFromHistory = (historyItems: SalaryForkHistoryItem[]): string[] => {
    const salaryIdFields = ['bySalaryFromId', 'bySalaryToId', 'plSalaryFromId', 'plSalaryToId'];

    const salaryIds = new Set<string>();

    historyItems.forEach(item => {
      Object.values(item.changes || {}).forEach(changesList => {
        if (!Array.isArray(changesList)) return;

        changesList.forEach((change: SalaryForkChangeItem) => {
          if (salaryIdFields.includes(change.property)) {
            if (change.newValue) salaryIds.add(change.newValue);
            if (change.oldValue) salaryIds.add(change.oldValue);
          }
        });
      });
    });

    return Array.from(salaryIds).filter(id => id !== '');
  };

  const fetchEmployeeNames = async (aliases: string[]): Promise<EmployeeNamesMap> => {
    if (!aliases.length) return {};

    const employeeData = await getEmployeeNamesByAliases(aliases);

    const employeeMap: Record<string, any> = {};
    employeeData.forEach(employee => {
      if (employee && employee.alias) {
        employeeMap[employee.alias] = employee;
      }
    });

    const namesMap: EmployeeNamesMap = {};
    aliases.forEach(alias => {
      const employee = employeeMap[alias];
      namesMap[alias] = employee ? employee.name : undefined;
    });

    return namesMap;
  };

  const fetchSalaryValues = async (salaryIds: string[]): Promise<SalaryValuesMap> => {
    if (!salaryIds.length) return {};

    const salaryValues = await searchSensitiveRecruitingInfo(salaryIds);
    return createSalaryMap(salaryValues);
  };

  const findSpecializationAlias = (historyItems: SalaryForkHistoryItem[]): string => {
    return [...historyItems].reverse().reduce((foundAlias, item) => {
      if (foundAlias !== 'unknown') return foundAlias;

      if (!item?.changes) return foundAlias;

      return Object.values(item.changes).reduce((alias, category) => {
        if (alias !== 'unknown' || !Array.isArray(category)) return alias;

        const specChange = category.find(c => c.property === 'specialization');
        return specChange?.newValue || alias;
      }, foundAlias);
    }, 'unknown');
  };

  const getSalaryForksHistory = async (specializationId: string) => {
    try {
      const params = {
        pageNumber: 0,
        pageSize: 100,
      };

      const response = await sendPostRequest(`${SALARY_FORKS}/${specializationId}/history`, params);
      const historyItems = await response.json();

      if (!historyItems || !Array.isArray(historyItems) || historyItems.length === 0) {
        return {
          historyItems: [],
          specializationName: 'Unknown',
          employeeNamesMap: {},
          salaryValuesMap: {},
        };
      }

      let specializationName = 'Unknown Specialization';
      const specializationAlias = findSpecializationAlias(historyItems);
      const specializations = await getSpecializationOptions();
      const specialization = specializations.find((spec: any) => spec.alias === specializationAlias);
      specializationName = specialization?.name || `Specialization ${specializationAlias}`;

      const employeeAliases = extractEmployeeAliasesFromHistory(historyItems);
      const salaryIds = extractSalaryIdsFromHistory(historyItems);

      const [namesMap, valuesMap] = await Promise.all([
        fetchEmployeeNames(employeeAliases),
        fetchSalaryValues(salaryIds),
      ]);

      return {
        historyItems,
        specializationName,
        employeeNamesMap: namesMap || {},
        salaryValuesMap: valuesMap || {},
      };
    } catch (error) {
      return {
        historyItems: [],
        specializationName: 'Error',
        employeeNamesMap: {},
        salaryValuesMap: {},
      };
    }
  };

  const saveSensitiveRecruitingInfo = async (sensitiveInfo: SensitiveRecruitingInfo[]) => {
    return saveBatchSensitiveRecruitingInfo(sensitiveInfo);
  };

  const getCreateValidationSchema = () => Promise.resolve(createSalaryForkSchema);
  const getUpdateValidationSchema = () => Promise.resolve(updateSalaryForkSchema);

  return {
    baseSearch,
    getSpecializationOptions,
    getCreateValidationSchema,
    getUpdateValidationSchema,
    createSalaryFork,
    updateSalaryFork,
    searchSalaryForksForSpec,
    createInitialData,
    getSalaryForksHistory,
    ...baseCrud,
  };
};

export default useSalaryForkService;
