/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useEmployeeService, { DOCUMENTS_ALIAS, EMPLOYEE_TYPES_ALIAS } from 'erp/employee/employeeService';
import useCandidateConversionService from 'erp/candidate/candidateConversionService';
import useConversionRouteHelper from 'erp/employee/conversionRouteHelper';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';
import ResourcePoolDropdown from 'erp/employee/shared/input/ResourcePoolDropdown';
import {
  AddressInput,
  DateInput,
  GenderRadioButtonGroup,
  NameInput,
  PhoneInput,
  TextInput,
} from 'shared/uibuilder/form/input';
import Loading from 'shared/uibuilder/Loading';
import { BELARUS } from 'shared/uibuilder/form/input/CountryDropdown';
import CreateEmployeeBreadcrumbs from 'erp/employee/createupdate/Create/Breadcrumbs';
import ConvertCandidateDropdown from 'erp/employee/shared/input/ConvertCandidateDropdown';
import BigFormRow from 'shared/layout/form/BigFormRow';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import EmployeeTypeDropDown from 'erp/employee/shared/input/EmployeeTypeDropdown';
import EmployeeWorkplaceDropdown from 'erp/employee/shared/input/EmployeeWorkplaceDropdown';
import IdentityDocumentListInput from '../../shared/input/IdentityDocumentInput/IdentityDocumentListInput';
import IdentityDocumentInput from '../../shared/input/IdentityDocumentInput/IdentityDocumentInput';
import PeopleManagementSpecifySection from 'erp/employee/shared/input/PeopleManagementSpecifySection';

const CreateEmployeePage = () => {
  const { create, getValidationSchema } = useEmployeeService();
  const { getCandidate, mapCandidateToEmployee, convertCandidate } = useCandidateConversionService();
  const { candidateId } = useConversionRouteHelper();
  const { getListUrl } = useEmployeeUrl();

  const submitMethod = candidateId ? convertCandidate : create;

  const { loading } = getCandidate();

  return loading ? (
    <Loading />
  ) : (
    <CreateForm
      submitFormFunc={submitMethod}
      initialData={mapCandidateToEmployee({
        extendedInformation: {
          documentType: DOCUMENTS_ALIAS.PASSPORT,
          passportData: {
            citizenship: BELARUS.name,
          },
          registrationAddress: {
            country: BELARUS.name,
          },
          additionalContactInfo: [],
          identityDocuments: [{}],
        },
        assignedPeopleManagerAliases: [],
        isAccessForAllPeopleManagers: false,
      })}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Employee has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout breadcrumbs={<CreateEmployeeBreadcrumbs />} title="Create: Employee">
        <FormSection title="Convert from candidate">
          <FormRow>
            <ConvertCandidateDropdown source="candidateId" label="Candidate" />
          </FormRow>
        </FormSection>
        <FormSection title="Main information">
          <BigFormRow>
            <EmployeePhotoUploaderInput label="Employee photos" source="extendedInformation.photosIds" />
          </BigFormRow>
          <NameInput label="Local Name" source="nameLocal" middleNameLabel="Patronymic Name" />
          <NameInput label="Latin Name" source="nameEn" middleNameLabel="Middle Name" />
          <FormRow>
            <DateInput source="extendedInformation.dateOfBirth" label="Date of Birth" />
            <GenderRadioButtonGroup source="gender" label="Gender" className="gender-radio-button-group" />
            <TextInput
              label="Syberry Email"
              source="workingEmail"
              isVisible={data => data.employeeType === EMPLOYEE_TYPES_ALIAS.CONTRACTOR}
            />
          </FormRow>
        </FormSection>

        <FormSection title="HR info">
          <FormRow>
            <EmployeeTypeDropDown label="Type" source="employeeType" />
            <PositionDropdown source="positionId" label="Position" />
            <ResourcePoolDropdown source="resourcePoolId" label="Resource Pool" />
            <EmployeeWorkplaceDropdown source="extendedInformation.hrInformation.workplaceLocation" />
          </FormRow>
          <PeopleManagementSpecifySection />
        </FormSection>

        <FormSection title="Identity documents" hasAnchorLink>
          <BigFormRow>
            <IdentityDocumentListInput
              source="extendedInformation.identityDocuments"
              inputTemplate={IdentityDocumentInput}
              addText="Add document"
            />
          </BigFormRow>
        </FormSection>

        <FormSection title="Contact information">
          <FormRow>
            <PhoneInput source="extendedInformation.personalPhoneNumber" />
            <TextInput source="extendedInformation.personalEmail" label="Personal Email Address" />
            <PhoneInput source="extendedInformation.otherPhoneNumber" />
          </FormRow>

          <AdditionalContactListInput source="extendedInformation.additionalContactInfo" />
        </FormSection>

        <FormSection title="Registration address">
          <AddressInput hasAddressLine2 source="extendedInformation.registrationAddress" />
        </FormSection>

        <FormSection
          title="Requisites"
          isVisible={employee => employee.employeeType === EMPLOYEE_TYPES_ALIAS.CONTRACTOR}
        >
          <FormRow>
            <TextInput source="extendedInformation.requisitesInformation.requisites" label="Requisites" />
          </FormRow>
          <DocumentScanUploaderInput
            source="extendedInformation.requisitesInformation.scansIds"
            label="Document scans"
          />
        </FormSection>
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeePage;
